
  
  
/* Container for the form group and the image */
.booking-container1 {
  display: flex;
   flex-direction: row; /* Ensures the form and image are side by side */
   width: 100%;
   height: 100vh; /* Full viewport height */
   box-sizing: border-box;
   align-items: flex-start; /* Aligns items at the top */
   justify-content: space-between; /* Provides space between form and image */
 }
 .product-check input {
   height: 70px;
   
 }
 .booking-container1 h1 {
   position: absolute;
   width: 182px;
   height: 26px;
   left: 280px;
   top: 130px;

   font-family: 'Public Sans';
   font-style: normal;
   font-weight: 500;
   font-size: 22px;
   line-height: 26px;
   /* identical to box height */

   color: #000000;

 }
 .booking-container1 h8 {
   position: absolute;
   width: 260px;
   height: 26px;
   left: 20px;
   margin-top: 50px;

   font-family: 'Public Sans';
   font-style: normal;
   font-weight: 500;
   font-size: 22px;
   line-height: 26px;
   /* identical to box height */

   color: #000000;

 }

 .booking-container1 h9 {
   position: absolute;
   width: 182px;
   height: 26px;
   left: 20px;
   margin-top: 10px;

   font-family: 'Public Sans';
   font-style: normal;
   font-weight: 500;
   font-size: 22px;
   line-height: 26px;
   /* identical to box height */

   color: #000000;


 }

 .availability-toogle-button {
   background-color: #FFFFFF; /* Bootstrap primary color */
   color: #000000; /* Text color */
   border: none; /* No border */
   border-radius: 5px; /* Rounded corners */
   padding: 10px 20px; /* Padding for the button */
   cursor: pointer; /* Pointer cursor on hover */
   font-size: 16px; /* Font size */
   margin-top: 30px;
   width: 1200px;
   margin-left: 250px; /* Space above the button */
}

.availability-toogle-button:hover {
   background-color: #FFFFFF; /* Darker shade on hover */
   transition: background-color 0.3s ease; /* Smooth transition */
}

.availability1-toogle-button {
   /* Bootstrap primary color */
   color:#FFFFFF; /* Text color */
   border: none; /* No border */
    /* Rounded corners */
   padding: 10px 20px; /* Padding for the button */
   cursor: pointer; /* Pointer cursor on hover */
    /* Font size */
   margin-top: 33px;
   width: 265px;
   height: 42px;
   margin-left:650px ; 
   
   background: #164DC3;
   border-radius: 5px;/* Space above the button */
}

.availability1-toogle-button:hover {
  background: #164DC3; /* Darker shade on hover */
   transition: background-color 0.3s ease; /* Smooth transition */
   color: #ffffff;
}
 
 /* Form group section */
 .form-group1 {
   
  margin-left: 20px;
  max-width:700px;
  margin-right: auto;
 
  /* Add some padding for spacing */
   box-sizing: border-box;
   padding-top: 0px;
   
  
  
   
    /* You 
    can change the background color as needed */
 }
 .form-group1 input {
   background: #EEEEEE;
   
   border-radius: 5px;
   height: 30px;
   width:200px;
 }

 .customer-details-form .form-group1 select {
   margin-left: 10px;
   
 }
 .receipt-column1 {
   flex: 1;
   width: 600px; /* Adjust as needed */
   padding: 05px;
   text-align: center;
   font-family: 'Public Sans';
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 16px;
   margin-left: 0%;
   margin-top: 0%;
   background-color: #f9f9f9;
   color: #000000;
 }
 .receipt-column1 input{
   width:600px
   
 }
 /* Image section */
 .product-image-container1{
  margin-top: -450px;
  margin-left: 800px;
  height: 470px;
  width: 300px;
  

    /* Replace with your image URL */
   background-size: cover; /* Ensures the image covers the area */
   background-position: center;
   box-sizing: border-box;
 }
 .product-details {
  
  
   margin-left: 1100px;
   
 
     /* Replace with your image URL */
    background-size: cover; /* Ensures the image covers the area */
    background-position: center;
    box-sizing: border-box;
  }
  .product-image1 {
    margin-top: -50px;
    width: 300px; /* Keep the width as per your design */
    height: 450px; /* Increase the height */
    border-radius: 20px;
    object-fit: cover; /* Ensure the image scales well and fills the container */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for better appearance */
  }
 /* Optional: Styling the form inside the form group */
 .form-group1 form {

   display: flex;
   flex-direction: column;
   gap: 15px; /* Space between form fields */
 }

 .form-group1 label{
   font-family: 'Public Sans';
   font-style:normal;
   font-weight: 600;
   font-size: 16px;
   line-height: 19px;
   /* identical to box height */
   padding:10px;

   color: #000000;

 }
 
 /* Styling form inputs */
 .form-group1 input,
 .form-group1 select,
 .form-group1 button {
   width: 100%;
  
   padding: 5px;
   border-radius: 5px;
   border: 1px solid #ccc;
   font-size: 16px;
 }
 
 /* Styling the button */
 .form-group1 button {
 
 
   cursor: pointer;
   margin-left: 400px;

   
  background: #008101;
  border-radius: 5px;
 }
 
 .form-group1 button:hover {
   background-color: #008101;
 }
 
 .checkavailability{
   cursor: pointer;
  
   color: #FFFFFF;
   position: absolute;
   width: 265px;
   height: 42px;
   left: 350px;
   margin-top: 62px;

   background: #008101;
   border-radius: 5px;
   border-color: #FFFFFF;

   
 }
 .checkavailability:hover{
   background: #008101;
   color: #FFFFFF;
 }

 .checkavailability11{
   cursor: pointer;
  
   color: #000000;
   position: absolute;
   width: 265px;
   height: 42px;
   left: 50px;
   margin-top: 32px;

   background: #EEEEEE;
   border-radius: 5px;
   border-color: #FFFFFF;


   
 }

 .checkavailability11:hover{
   color: #000000;
   background-color: #EEEEEE;
   border-radius: 5px;
   border-color: #FFFFFF;
 }
 .mm{
   margin-top: 500px;
 }
 .available-quantity-display{
   margin-left: 480px;
   margin-top: -90px;
   font-family: 'Public Sans';
   font-style: normal;
   font-weight: 600;
   font-size: 20px;
   line-height: 21px;

   color: #008101;
   
 }
 .customer-details-form{
    
   padding: 10px;
    border: 1px solid #ddd;
    background:#ffffff;
    width: 150%;

 }

 .total-quantity-display {
   display: flex;
    /* Ensure the items are spaced evenly */
   align-items: center; /* Align items vertically in the center */
   gap: 20px;
   margin-left: 40px;
   margin-top: 0px; /* Optional: Add space between the items */
 }
 
 .quantity-item1 {
   margin-right: 0px; /* Remove any default margin */
   /* Optional: Add some padding */
    /* Adjust text size */
   font-family: 'Public Sans';
   font-style: normal;
   font-weight: 600;
   font-size: 20px;
   line-height: 21px;
   padding: 10px;
   margin-top: 0px;

   color: #004DA8;

 }
 .quantity-item2 {
   margin-left: 0px; /* Remove any default margin */
   /* Optional: Add some padding */
   font-family: 'Public Sans';
   font-style: normal;
   font-weight: 600;
   font-size: 20px;
   line-height: 21px;
   padding: 10px;
   margin-top: 0px;


   color: #004DA8;/* Adjust text size */
 }

 .confirm-booking-button{
   cursor: pointer;
  
   color: #FFFFFF;
   position: relative;
   width: 265px;
   height: 42px;
   left: 150px;
   margin-top: 30px;

   background: #008101;
   border-radius: 5px;
   border-color: #FFFFFF;
   bottom: 30px;

 }
 .receiptconfirmpayment{
   cursor: pointer;
  
   color: #FFFFFF;
   position: relative;
   width: 265px;
   height: 42px;
   left: 600px;
   margin-top: 20px;

   background: #008101;
   border-radius: 5px;
   border-color: #FFFFFF;
  bottom: 30px;
 }
 .receipt-container {
   margin-left: 20px;
   margin-top: 100px;
   padding: 20px;
   border: 1px solid #ddd;
   border-radius: 8px;
   background-color: #f9f9f9;
   height:fit-content;
}

.receipt-details {
   display: flex;
   flex-direction: column;
}

.receipt-row {
   display: flex;
   justify-content: space-between;
   /* flex-wrap: wrap; */
   text-align: start;
   
}

.receipt-column {
   flex: 1;
   min-width: 120px; /* Adjust as needed */
   padding: 05px;
   text-align: center;
   font-family: 'Public Sans';
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 16px;

   color: #000000;

}

.receipt-values {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   box-sizing: border-box;
   height: 90PX;



       border-width: 0.5px 0px;
       border-style: solid;
       border-color: #000000;
}

.product-check{
 height: fit-content;
 width:150%;
 border-color: #ffffff;
 
 
}
.receipt-container span{
 color: #000000;
}
.cancel-button{
 margin-left: 1200px;
 

 
}

.submit-payment-button{
 background: #008101;
 border-radius: 5px;
 border-color: #FFFFFF;
 cursor: pointer;
  
   color: #FFFFFF;
   position: relative;
   width: 265px;
   height: 42px;
    left: 400px;
    bottom: 30px;
    margin-top: 20px;
}
.submit-payment-button:hover {
 background-color: #008101;

 
  
   color: #FFFFFF;
}
.payment-form-row label {
 padding:10px;
 font-family: 'Public Sans';
 font-style:normal;
 font-weight: 600;
 font-size: 16px;
 line-height: 19px;
 /* identical to box height */
 

 color: #000000;

}

.payment-form-row2 label {
 padding:10px;
 font-family: 'Public Sans';
 font-style:normal;
 font-weight: 600;
 font-size: 16px;
 line-height: 19px;
 /* identical to box height */
 

 color: #000000;
}
.payment-form-row2 select {
 width: 100px;
 padding:10px;
 margin-left: 10px;
}
.payment-form-row select{
 padding:10px;
 margin-left: 10px;
}

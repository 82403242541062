@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;700&display=swap');

*{
   font-family: 'Public Sans';
}

/* Overall container */
.dashboard-container {
  display: flex;
  flex-direction: column;
  max-width: 1500px; /* Constrain the width for desktop */
  height: 100%; /* Make container take the full height of the viewport */
  margin: auto; /* Center the container on the page */
  overflow: hidden; /* Hide overflow within the container */
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
}

.dashboard-container2 {
  display: flex;
  flex-direction: column;
  max-width: 1200px; /* Constrain the width for desktop */
  height: 80vh; /* Make container take the full height of the viewport */
  margin: auto; /* Center the container on the page */
  overflow: hidden; /* Hide overflow within the container */
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
}
.button10{
  
height: 50px;
width: 50px;
  border: none;
  border-radius: 4px;
  font-size: 1.17rem;
  cursor: pointer;
}
.button10:hover{
  padding: 10px 20px;
  
  
  border: none;
  border-radius: 4px;
  font-size: 1.17rem;
  cursor: pointer;
}
.dashboard-container1 {
  display: flex;
  flex-direction: column;
  /* Constrain the width for desktop */
  height: 100vh; /* Make container take the full height of the viewport */
 /* Center the container on the page */
  overflow: hidden; /* Hide overflow within the container */
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
}

/* Ensure the main content area allows vertical scrolling */
main {
  flex: 1;
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Content wrapper to allow scrolling */
.content-wrapper {
  flex: 1;
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 20px;
  min-height: 100%;
  /* Add these styles if needed */
  overflow-x: hidden;
}

/* Header styling */
.header {
  flex-shrink: 0; /* Prevent the header from shrinking */
  padding: 20px;
  display: flex; /* Ensure logo and profile align side by side */
  align-items: center; /* Vertically center items in header */
}

/* Logo styling */
.logo {
  margin-right: 5px;
  display: flex; /* Ensure logo elements are aligned correctly */
  align-items: center; /* Vertically center logo content */
}

.logo img {
  width: 50px;
  height: auto;
}

/* Profile styling */
.profile h3 {
  margin: 0;
  margin-left: 10px;
  font-size: 18px;
}

.profile p {
  margin: 10px  10px 0;
  font-size: 14px;
  color: #666;
}

/* Navigation menu styling */
.profile1 nav ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: space-around;
  border-bottom: 2px solid #D3D3D3;
}

.profile1 nav ul li {
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  padding-bottom: 10px;
}

/* Remove underline from NavLink */
.profile1 nav ul li a {
  text-decoration: none; /* Remove underline */
  color: black; /* Optional: inherits the text color from its parent */
}


.profile1 nav ul li:hover {
  font-weight: bolder;
}

.profile1 nav ul li::before,
.profile1 nav ul li::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 2px;
  background: transparent;
  transition: background 0.3s ease;
}

.profile1 nav ul li::before {
  left: -100%;
  width: 300%;
}

.profile1 nav ul li::after {
  right: -100%;
  width: 300%;
}

.profile1 nav ul li:hover::before,
.profile1 nav ul li:focus::before,
.profile1 nav ul li:hover::after,
.profile1 nav ul li:focus::after {
  background: black; /* Highlight color */
}

/* Overview section */
.overview {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.overview-item {
  width: 32%;
  background-color: #ffffff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.overview-item h3 {
  margin-bottom: 15px;
  font-size: 16px;
  color: #333;
}

/* Performance section */
.performance {
  margin: 20px 0;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.super-admin-table {
  margin: 20px 0;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow-x: auto; /* Enable horizontal scrolling if table is too wide */
}

/* Table Styles */
.super-admin-table table {
  width: 100%;
  border-collapse: collapse; /* Collapse borders to avoid extra spacing */
  table-layout: fixed; /* Ensure columns respect container width */
}

/* Table Header and Cells */
.super-admin-table table th,
.super-admin-table table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
}

/* Table Header */
.super-admin-table table th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
}

/* Table Rows */
.super-admin-table table tr:nth-child(even) {
  background-color: #f9f9f9;
}
/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .logo h2 {
    font-size: 20px; /* Adjust font size for logo on smaller screens */
  }

  .logo p {
    font-size: 12px; /* Adjust font size for logo description on smaller screens */
  }

  .overview {
    flex-direction: column; /* Stack overview items vertically on small screens */
  }

  .overview-item {
    width: 100%;
    margin-bottom: 20px;
  }

  .profile1 nav ul {
    align-items: flex-start; /* Align navigation items to the start */
  }

  .profile1 nav ul li {
    margin: 5px 0;
  }

  .super-admin-table table {
    font-size: 12px; /* Adjust font size for better readability on small screens */
  }

  .super-admin-table table th,
  .super-admin-table table td {
    padding: 5px; /* Reduce padding for better fit */
  }

  /* Allow horizontal scrolling if table is too wide */
  .super-admin-table {
    overflow-x: auto; /* Ensure table can scroll horizontally */
  }

  /* Optionally, allow wrapping in table cells for very narrow screens */
  .super-admin-table table td {
    white-space: normal; /* Allow text to wrap */
  }
  
  .profile1 nav ul li::before {
    left: -25%; /* Reduce the overflow to better fit smaller screens */
    width: 150%; /* Adjust width to maintain the hover effect */
  }

  .profile1 nav ul li::after {
    right: -25%; /* Reduce the overflow to better fit smaller screens */
    width: 150%; /* Adjust width to maintain the hover effect */
  }
  
}
.status-expiring-soon {
  background-color: yellow; /* or any other color you want */
  color: black;
}


    /* Hover effect for table rows in tbody */
    .table tbody tr:hover {
      background-color: #f0f8ff; /* Light blue background */
      transform: translateY(-1px) scale(1.02); /* Lift the row slightly and scale */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
      cursor: pointer; /* Pointer cursor */
    }
    
    /* Optional: Add smooth hover effect to table cells */
    .table tbody td {
      transition: color 0.3s ease;
    }
    
    .table tbody tr:hover td {
      color: #164DC3; /* Change text color on hover */
    }
    
.right-sidebar {
    position: fixed;
    top: 62px;
    right: -500px;
    width: 500px;
    height: calc(100% - 62px);
    color: #000000;
    transition: left 0.3s ease;
    padding: 20px;
    z-index: 1; /* Ensure it's above other content */
    background: #FFFFFF;
    box-shadow: 0px 4px 9.2px rgba(0, 0, 0, 0.25);
    border-radius: 0px 20px 20px 0px;
    overflow-y: auto; /* Make sidebar scrollable */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    box-sizing: border-box; /* Include padding in height calculation */
  
  }
  
  .right-sidebar.open {
    right: 0;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .sidebar-content {
    padding: 20px;
    overflow-y: auto; /* Allows scrolling if content overflows */
  }
  
  .sidebar-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Increased gap between rows */
  }
  
  .sidebar-item {
    flex: 1;
    margin-right: 10px;
  }
  
  .sidebar-item:last-child {
    margin-right: 0;
  }
  
  .sidebar-item h3 {
    margin-bottom: 5px;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    color: #000000;
  }
  
  
  
  .sidebar-item p,
  .sidebar-item select,
  .sidebar-item input[type="text"],
  .sidebar-item input[type="date"] {
    margin: 0;
    padding: 6px;
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    height: 30px;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #000000;
  }
  
  .sidebar-item p {
    height: 30px;
    display: flex;
    align-items: center;
  }
  
  .save-button {
    background-color: #4CAF50; /* Green color */
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .save-button:hover {
    background-color: #45a049;
  }
  
  /* Full row for comments */
  .comment-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px; /* Space above comment section */
  }
  
  .comment-section textarea {
    width: 500px;
    max-width: 5%;
    height: 80px; /* Adjust height as needed */
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-family: 'Public Sans';
    font-size: 13px;
    line-height: 15px;
    background-color: #f9f9f9;
  }
  
  .previous-comments {
    margin-top: 10px;
  }
  
  .previous-comments h3 {
    margin-bottom: 5px;
    font-family: 'Public Sans';
    font-weight: 600;
    font-size: 13px;
  }
  
  .previous-comments ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .previous-comments li {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 5px;
    font-family: 'Public Sans';
    font-size: 13px;
    color: #000;
  }
  .radio-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  
  .radio-group label {
    font-size: 14px;
    margin-right: 20px;
  }
  
  
  .save-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .save-button {
    margin-right: 10px; /* Adjust the space between the button and the icon */
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
  }
  
  .save-button:hover {
    background-color: #45a049;
  }
  
  .whatsapp-icon {
    cursor: pointer;
  }
  .whatsapp-container {
    position: relative;
  }
  
  .dropdown-box {
    position: absolute;
    top: 40px; /* Adjust this value based on the size of your icon */
    left: 0;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
    width: 200px; /* Adjust width as needed */
  }
  
  .dropdown-item {
    display: block;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background: white;
    cursor: pointer;
    text-align: left;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .dropdown-toggle {
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .save-button-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .whatsapp-icon {
    cursor: pointer;
  }
  .overlay-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px; /* Adjust width as needed */
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .overlay-box h3 {
    margin: 0 0 10px;
  }
  
  .template-editor {
    display: flex;
    flex-direction: column;
  }
  
  .template-textarea {
    width: 100%;
    height: 150px;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .send-button {
    align-self: flex-start;
    background-color: #25D366;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .send-button:hover {
    background-color: #1e8e6f;
  }
  
  .overlay-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
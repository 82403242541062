/* src/components/activeLog.css */

.active-log {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .active-log h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .active-log .error-message {
    color: #dc3545;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .active-log table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .active-log th,
  .active-log td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .active-log th {
    background-color: #007bff;
    color: white;
  }
  
  .active-log tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
body{
  overflow-y: auto;
}


.add-product-container {
  width: 95%;
  margin: 0 auto;
  padding: 20px  ;
  
  font-family: 'Arial', sans-serif;
}

.product-form {
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 20px;
  
  
}
.product-form input{
  width: 100%;
}


.general-info {
  grid-column: 1 / 2;
}
.left,  .bottom-left, .bottom-right{
  width: 45%;
  width: auto;
  height: auto;
  background-color: #ebebeb;
  border-radius: 10px;
  padding: 40px;
  
  
}

.right{
  width: 45%;
  width: auto;
  height: auto;
  background-color: #ebebeb;
  border-radius: 10px;
  padding: 40px;
}
.image-upload-box{
  width: 45%;
  width: auto;
  height: 300px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 40px;
}

.right1{
  width: 45%;
  width: auto;
  height: 50px;
  background-color: #ebebeb;
  border-radius: 10px;
  padding: 40px;
}


.image{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  background-color: #b6b6b6;
  height: 70%;
  width: auto;
  border-radius: 10px;
}

.image-preview {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  background-size: cover;
  margin-top: 15px;
}

.pd{
  margin-bottom: 20px;
  color: #000000;
}


label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
  margin-left: 10px;
}

input, textarea, select{
  width: 100%;
  margin-left: 10px;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 14px;
}

.extra-rent-group input{
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 14px;
}

textarea {
  height: 80px;
}

input[type="file"] {
  border: none;
  margin-top: 10px;
}

.size-gender {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.size-options button {
  padding: 8px;
  margin-right: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
}

.size-options button.active{
  background-color: #28a745;
  color: white;
}


.extra-rent-group button.active{
  background-color: #28a745;
  color: white;
}


.image-upload {
  text-align: center;
}

.image-upload img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 8px;
}

.image-upload input {
  margin-top: 10px;
}

.upload-preview {
  display: flex;
  align-items: center;
}

#branch-code {
  float: right;
  font-size: 12px;
  color: #666;
}


.can {
margin-top: 70px;
background-color: #ebebeb;
color: #000;

height: 33px;

border: none;
border-radius: 5px;
font-family: 'Public Sans', sans-serif;
font-weight: 500;
font-size: 13px;

cursor: pointer;
width: 100%;
}



.can1 {
  margin-top: 20px;
  background-color: #ebebeb;
  color: #000;
  
  height: 33px;
  
  border: none;
  border-radius: 5px;
  font-family: 'Public Sans', sans-serif;
  font-weight: 500;
  font-size: 13px;
  
  cursor: pointer;
  width: 100%;
  }
.pro{
  margin-top: 20px;
  height: 33px;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  font-family: 'Public Sans', sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #FFFFFF;
  cursor: pointer;
  width: 100%;
}
.pro :hover{
  background-color: #00e000;
}




.radio-group {
  display: flex;
  justify-items: end;
  margin-bottom: 5px;
  margin-right: 100px;
}

.radio-group label {
  font-size: 14px;
  margin-right: 20px;
}

.radio-group input{
  height: auto;
  width: auto;
}
.sidebar-row{
  margin-right: auto;
  display: flex;
}




.container {
  border: 2px dashed #000000;
  padding: 20px;
  border-radius: 20px;
  max-width: 400px;
  width: 100%;
  background-color: #ffffff;
  text-align: center;
}

.container h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

#previewContainer {
  width: 100%;
  height: 300px;
  border: 2px dashed #999;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

#previewContainer::before {
  content: '+';
  font-size: 2rem;
  color: #00e000;
}

.small-previews {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.small-previews div {
  width: 80px;
  height: 80px;
  border: 2px dashed #999;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="file"] {
  display: none;
}


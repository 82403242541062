.change-password {
  max-width: 400px; /* Set a max width for the form */
  margin: 20px auto; /* Center the form on the page */
  padding: 20px; /* Add padding for better spacing */
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  background-color: #f9f9f9; /* Light background color */
}

.change-password h2 {
  text-align: center; /* Center the heading */
  margin-bottom: 20px; /* Space below the heading */
  color: #333; /* Darker text color */
}

.change-password form {
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack items vertically */
}

.change-password .MuiTextField-root {
  margin-bottom: 16px; /* Space between text fields */
}

.change-password button {
  margin-top: 10px; /* Space above the button */
}

.error-message {
  color: red; /* Red color for error messages */
  text-align: center; /* Center the error message */
}

.success-message {
  color: green; /* Green color for success messages */
  text-align: center; /* Center the success message */
}

/* Centering the main headings */
.sales-report-container h2, h3 {
  text-align: center; /* Center align main headings */
  margin-bottom: 20px; /* Adds space below the heading */
  color: #333; /* Dark color for headings */
}

/* Container for the sales report */
.sales-report-container {
  max-width: 1200px; /* Max width for better layout */
  margin: 0 auto; /* Center the container */
  padding: 20px;
  background: white; /* White background for the report */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Flexbox for filters */
.filters-container {
  display: flex;
  justify-content: space-between; /* Space between date filters and search/export buttons */
  align-items: center;
  margin-bottom: 20px; /* Space below filters */
}

/* Date filters */
.date-filters {
  display: flex;
  gap: 10px; /* Space between date filter elements */
  flex: 1; /* Allow date filters to take available space */
}

/* Search and Export button area */
.search-and-export {
  display: flex;
  gap: 10px;
  align-items: center; /* Center align items vertically */
}

/* Input field styling */
.search-and-export input {
  padding: 5px;
  border: 1px solid #ddd; /* Light border */
  border-radius: 4px; /* Rounded corners */
}

/* Search and export button styling */
.search-and-export button {
  padding: 10px 10px;
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: right;
  align-items: right;
}

.search-and-export button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Sales table styling */
.sales-table {
  width: 100%;
  border-collapse: collapse; /* Remove spacing between cells */
  margin-bottom: 20px; /* Space below table */
}

.sales-table th,
.sales-table td {
  border: 1px solid #ddd; /* Light border for cells */
  padding: 8px;
  text-align: center; /* Center align table headings and cells */
}

.sales-table th {
  background-color: #F3EFFC; /* Light gray background for header */
}

.sales-table tr:hover {
  background-color: #f1f1f1; /* Highlight row on hover */
}

/* Pagination styling */
.pagination {
  display: flex;
  justify-content: space-between; /* Space between buttons */
  align-items: center;
  margin-top: 20px; /* Space above pagination */
}

.pagination button {
  padding: 5px 10px;
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.pagination button:disabled {
  background-color: #ccc; /* Gray background for disabled */
  cursor: not-allowed; /* Not-allowed cursor for disabled */
}

/* Back button styling */
.back-button {
  padding: 5px 10px;
  margin-bottom: 20px; /* Space below button */
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Horizontal line style */
.table-divider {
  border: none; /* Remove default border */
  height: 3px; /* Set height */
  background-color: #ddd; /* Line color */
  margin: 70px 0; /* Spacing above and below */
}

/* Totals section styling */
.totals {
  background-color: #f8f9fa; /* Light background for totals section */
  padding: 15px;
  border-radius: 5px; /* Rounded corners */
  margin-top: 20px; /* Space above totals */
  display: flex; /* Flex layout for totals */
  justify-content: space-between; /* Space between totals items */
}

/* Individual total item */
.totals-item {
  flex: 1; /* Allow items to grow */
  margin: 0 10px; /* Space between items */
  text-align: center; /* Center align totals */
}

.totals h4 {
  margin: 0; /* Remove default margin */
}
.sales-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.date-filters {
  display: flex;
  gap: 1rem;
}

.search-filters {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.search-filters input {
  margin-right: 0.5rem;
}


/* src/components/customize.css */
.customize-form {
  padding: 20px;
  background-color: #f9f9f9;
}

.customize-form h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.customize-form div {
  margin-bottom: 10px;
}

.customize-form label {
  display: flex;
  align-items: center;
}

.customize-form input[type="checkbox"] {
  margin-right: 10px;
}

.customize-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.customize-form button:hover {
  background-color: #0056b3;
}

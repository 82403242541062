/* General container styling */
body{
  overflow-y: auto;
}


.add-lead-container {
  width: 95%;
  margin: 0 auto;
  padding: 20px  ;
  background-color: #fff;
  font-family: 'Arial', sans-serif;
}



.form-left{
  width: 65%;
}
.form-right{
  width: 32%;
}
/* Header */
.add-lead-content h2 {
  
  font-size: 28px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.subheading {
  font-size: 16px;
  margin-left: 20px;
  color: #555;
  margin-bottom: 0%;
}

/* Form styling */
.add-user-form {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  
}
.lead{
  width: auto;
  height: 250px;
  background-color: #F7F7F7;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
}



.requirement-field, .source-field, .eventdate-field {
  margin-bottom: 15px;
  width: auto;
  height: 60px;
  background-color: #F7F7F7;
  border-radius: 10px;
  padding: 10px;
  
  
  
}



 .comment-field {
  margin-bottom: 15px;
  width: auto;
  height: 80px;
  background-color: #F7F7F7;
  border-radius: 10px;
  padding: 10px;
  
  
}


.lead-details label, .requirement-field label, .source-field label, .eventdate-field label, .comment-field label {
  display: flex;
  margin: 8px;
  font-size: 14px;
  color: #333;
  
}


.lead-details input, 
.requirement-field input, 
.eventdate-field input, 
.comment-field textarea {
  width: 100%;
  padding: 10px;
  height: 30px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 14px;
}

.lead-details input{
  width: 100%;
  padding: 10px;
  height: fit-content;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 14px;
}

.source-field select{
  width: 100%;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  
}

.comment-field textarea {
  height: 50px;
  resize: vertical;
}

.sub-left {
  display: inline-block;
  margin-bottom: 15px;
  margin-right: 20px; /* Add space between sub-left and sub-right */
  width: 46%;
  height: 80px;
  background-color: #F7F7F7;
  border-radius: 10px;
  padding: 10px;
  justify-content: space-between;
}

.sub-right {
  display: inline-block;
  margin-bottom: 15px;
  width: 46%;
  height: 80px;
  background-color: #F7F7F7;
  border-radius: 10px;
  padding: 10px;
  justify-content: space-between;
}


.sub-left label, .sub-right label {
  display: flex;
  margin: 8px;
  font-size: 14px;
  color: #333;
}

.sub-left input{
  width: 100%;
  height: 40px;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 14px;
}
.sub-right select {
  width: 100%;
  padding: 10px;
  height: 40px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;

}
/* Button group */
.button-group {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
}

.btn {
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-weight: bold;
}

.cancel {
  background-color: #ccc;
  color: #333;
}

.add-clead {
  background-color: #28a745;
  color: #fff;
}

.add-employee:hover {
  background-color: #176d2b;
}

.cancel:hover {
  background-color: #999;
}

/* Sidebar and Header */
.sidebar-open .add-lead-content {
  margin-left: 250px;
  transition: margin-left 0.3s ease;
}

@media (max-width: 768px) {
  .add-lead-content {
    margin-left: 0;
  }

  .add-user-form {
    flex-direction: column;
  }

  .form-left, .form-right {
    width: 100%;
  }

  .sub-left, .sub-right {
    width: 100%;
  }

  .button-group {
    justify-content: center;
  }
}


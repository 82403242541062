.form{
    display: flex;
    justify-content:space-between;
    margin-right: 50%;
}

label{
    font-weight: bold;
}

.input{
    display: flex;
    justify-content:space-between;
    margin-right: 29vw;
}

input{
    border: 1px solid #000000;
    border-radius: 3px;
    padding-right: 5%;
    height: 30px;
}

.btn {
    display: flex;
    justify-content: flex-end;
    height: 40px;
}

.btn1{
    border: none;
    background: linear-gradient(to bottom, #175CFF, #0E3799);
    color: #FFFFFF;
    padding: 10px;
    border-radius: 5px;
}

button:hover{
    cursor: pointer;
    background: #3b76ff;
    color: #000000;

}

.btn-group{
    display: flex;
    justify-content: flex-end;
    height: 40px;
}

.Confirm,.Cancel{
    margin: 3px ;
    padding: 10px;
}

.Confirm{
    background: green;
}

.Confirm:hover{
    background: rgb(0, 255, 30);
}
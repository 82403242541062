/* ForgotPassword.css */
.forgot-password-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .success-message {
    color: green;
  }
  
  .error-message {
    color: red;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  #email {
    width: 100%;
    padding: 10px;
  }
  
  .reset-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .reset-button:hover {
    background-color: #0056b3;
  }
  
/* Additional styling for table and other elements */
.dashboard-container {
  background-color: #ffffff;
  overflow-x: auto;
}

.table-container {
  margin-top: 80px;
  width: 100%;
  max-height: 500px;
  /* Adjust as needed for vertical overflow */
  overflow-y: auto;
  /* Enable vertical scrolling */
  position: relative;
  /* For positioning the scrollbar wrapper */
}


.filter-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.filter-container button {
  padding: 10px 20px;
  background-color: #F3EFFC;
  border: none;
  border-radius: 5px;
  color: #333;
  font-size: 16px;

  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.filter-container button:hover {
  background-color: #e1d6f9;
  color: white;
}

.filter-container button:active {
  background-color: #e1d6f9;
  color: white;
}

.filter-container button:focus {
  background-color: #e1d6f9;
  color: #606060;
  outline: none;
}




.search-bar-container9 {
  display: flex;
  align-items: center;
  position: relative;
  height: 33px;
  background-color: white;
  border: 1px solid #A39D9D;
  border-radius: 5px;
  overflow: hidden;
  width: 260px;
}

/* Styles for the search input */
.search-bar-container9 input {
  padding: 10px;
  width: 100px;
  border: none;
  outline: none;
  font-family: 'Public Sans', sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #666666;
  background-color: transparent;
}

/* Styles for the search dropdown */
.search-dropdown9 {
  padding: 25px;
  width: 80px;
  border: none;
  outline: none;
  font-family: 'Public Sans', sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #2d2c2c;
  background-color: transparent;
  cursor: pointer;
  appearance: none;
  border-left: 1px solid #A39D9D;
}

/* Align the search icon */
.search-icon9 {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}




/* Container wrapper to manage relative positioning */




/* Create a wrapper for horizontal scroll */

/* Table styling */
.table {
  text-align: left;
  width: 100%;
  /* Ensure table takes full width of its container */
  border-collapse: collapse;
  box-sizing: border-box;
  margin-top: 20px;
  /* Include padding and border in width calculations */

}

/* Table headers and cells styling */
.booking-table th,
.booking-table td {
  padding: 15px;
  border-bottom: 1px solid #F3EFFC;
  font-family: 'Public Sans', sans-serif;
  font-size: 13px;
}

.booking-table th {
  border: 1px solid #ddd;
  height: 40px;
  font-weight: 600;
  color: #000000;
  position: sticky;
  /* Use sticky positioning */
  top: 0;
  /* Stick to the top */
  background-color: #F3EFFC;
  /* Set a background color */
  z-index: 1;
  /* Ensure it stays above the table rows */
  padding: 10px;
  /* Padding for spacing */
}

.booking-table td {
  border: 1px solid #ececec;
  background: #FFFFFF;
  font-weight: 400;
  color: #000000;
}

/* New styles for status colors */
.table td.status-active {
  color: #008101;
}

.table td.status-deactive {
  color: #FF0000;
}



/* Styles for the Create Branch button */

/* Styles for filter buttons */
.filter-buttons-container {
  margin: 20px 0;
}

.filter-buttons-container span {
  cursor: pointer;
  margin-right: 10px;
}

.active-filter {
  font-weight: bold;
}





/* Parent container for search bar, action buttons, and create branch button */
.toolbar-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  position: absolute;
  top: 120px;
  right: 20px;
}

/* Styles for search bar container */





.import-button,
.export-button {
  background-color: #164DC3;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;

}


.import-button svg,
.export-button svg,
.add-product-button svg {
  margin-right: 5px;
}

.add-product-button {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
}


/* Create Branch Button */
.create-branch-container {
  height: 33px;
}

.create-branch-container button {
  height: 33px;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  font-family: 'Public Sans', sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #FFFFFF;
  cursor: pointer;
  width: 133px;
}


.product-image {
  /* Set a maximum height for the image */
  width: 40px;
  /* Adjust width automatically */
  height: 40px;
  /* Adjust height automatically */
  object-fit: cover;
  /* Cover the defined dimensions while maintaining aspect ratio */
  border-radius: 8px;
  /* Optional: Add rounded corners */
}

.dashboard-container.sidebar-open .dashboard-content {
  margin-left: 250px;
  /* Adjust this value based on your sidebar width */
  transition: margin-left 0.3s ease;
  /* Smooth transition */
}





/* Hover effect for table rows in tbody */
.booking-table tbody tr:hover {
  background-color: #f0f8ff;
  /* Light blue background */
  transform: translateY(-1px) scale(1.02);
  /* Lift the row slightly and scale */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a subtle shadow */
  cursor: pointer;
  /* Pointer cursor */
}

/* Optional: Add smooth hover effect to table cells */
.booking-table tbody td {
  transition: color 0.3s ease;
}

.booking-table tbody tr:hover td {
  color: #164DC3;
  /* Change text color on hover */
}

/* Disable hover effect when modal is open */
body.modal-open .booking-table tbody tr:hover {
  background-color: transparent;
  /* Disable background color change */
  transform: none;
  /* Disable the transform effect */
  box-shadow: none;
  /* Remove shadow */
  cursor: default;
  /* Change cursor to default */
}

body.modal-open .booking-table tbody tr:hover td {
  color: inherit;
  /* Don't change text color */
}

/* Modal Overlay - smooth transition */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  /* Dim background */
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  /* Hidden by default */
  transition: opacity 0.3s ease, visibility 0s 0.3s;
  /* Fade-in/fade-out transition */
  pointer-events: none;
  /* Prevent interactions with background */
}

.modal-overlay.active {
  opacity: 1;
  visibility: visible;
  /* Make visible when modal is open */
  transition: opacity 0.3s ease, visibility 0s 0s;
  /* Immediate visibility change when modal is active */
  pointer-events: auto;
  /* Enable interactions when the modal is open */
}

/* Modal Popup style */
.modal-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-width: 400px;
  width: 90%;
}

/* Close button */
.close-button {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #ccc;
  color: #000000;
  border: none;
  cursor: pointer;
}

/* Template list style */
.template-list {
  list-style-type: none;
  padding: 0;
}

.template-item {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;
}

.template-item:hover {
  background-color: #e3f2fd;
  /* Light blue on hover */
}

/* WhatsApp Button */
.whatsapp-button {
  padding: 10px;
  border-radius: 5px;
  background-color: #25D366;
  color: white;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
}